<template>
<div>
    <h1>Authenticating...</h1>
</div>
</template>

<script>
export default {
    created() {
        const vm = this;
        const authorizationCode = this.$route.query.code;
        const state = this.$route.query.state;
        vm.$store
            .dispatch("authenticate", {
                authorizationCode,
                state,
            })
            .then(() => {
                vm.$store
                    .dispatch("getUser", false)
                    .then(() => {
                      window.console.log(`test redirectUri ${vm.$store.state.auth.pathRedirectUri}`);
                        if (vm.$store.state.auth.pathRedirectUri) {
                            vm.$router.push(`${vm.$store.state.auth.pathRedirectUri}`);
                        } else {
                            vm.$router.push({
                                name: "Client List"
                            });
                        }

                        //  vm.$router.push({
                        //         name: "Client List"
                        //     });
                    })
                    .catch(() => {
                        vm.$router.push({
                            name: "error",
                            params: {
                                message: "Error while fetching user information from Protego.",
                            },
                        });
                    });
                // const onboarded = vm.$store.getters.isOnboardedUser;
                // if (onboarded) {
                //   vm.$router.push(vm.$store.getters.requestedRoute);
                // } else {
                //   vm.$router.push({ name: 'Client List' });
                // }
            });
    },
};
</script>
